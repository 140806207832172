/* Do not edit any information in this file */

import AxiosError from 'axios/lib/core/AxiosError'
import utils from 'axios/lib/utils'
import buildFullPath from 'axios/lib/core/buildFullPath'
import transformData from 'axios/lib/core/transformData'
import buildURL from 'axios/lib/helpers/buildURL'
import parseHeaders from 'axios/lib/helpers/parseHeaders'
import parseProtocol from 'axios/lib/helpers/parseProtocol'
// 精简内容:[
//     CancelToken|signal: 同步请求不中断
//     onDownloadProgress: 低频钩子
//     onUploadProgress:   低频钩子
//     xsrfCookieName: 项目手动添加
//     xsrfHeaderName: 项目手动添加
//     validateStatus: 低频功能
//     auth:           Authorization 低频功能
// ]
function onAdapterResolution(response, config) {
  response.data = transformData.call(
    config,
    response.data,
    response.headers,
    config.transformResponse
  )
  return response
}

// 抹平源码中同步异步请求对拦截器处理的行为差异
function interceptorRunner(interceptor, type, config, request, payload, inCatch = false) {
  const queue = [...interceptor]

  while (queue.length) {
    const { fulfilled, rejected } = queue[type]()
    const method = inCatch ? rejected : fulfilled
    if (!method) continue

    try {
      payload = method === onAdapterResolution
        ? method(payload, config)
        : method(payload)
      inCatch = false
    } catch (error) {
      payload = error
      inCatch = true
    }
    if (Object.prototype.toString.call(payload) === '[object Promise]') {
      payload = new AxiosError('Promise is unsupported in synchronizeInstance', 'INTERCEPTOR_ILLEGAL', config, request, payload)
      inCatch = true
      break
    }
  }
  return { payload, inCatch }
}

function handleReturnCallback(instance, config, request, response, inReject) {
  const { success, error, complete } = config
  if (typeof success === 'function' || typeof error === 'function') {
    const { payload, inCatch } = interceptorRunner(instance.interceptors.response.handlers, 'shift', config, request, response, inReject)
    if (inCatch) {
      if (typeof error === 'function') {
        error(payload)
      }
    } else if (typeof success === 'function') {
      success(payload)
    }
  }
  typeof complete === 'function' && complete(request)
}

function createSyncXhrAdapter(instance) {
  return async function SyncXhrAdapter(config) {
    try {
      ; (config => {
        // 请求拦截器
        const { payload, inCatch } = interceptorRunner(instance.interceptors.request.handlers, 'pop', config, null, config)
        if (inCatch) {
          handleReturnCallback(instance, config, null, payload, true)
          return
        }
        config = payload

        const requestHeaders = config.headers
        const responseType = config.responseType
        let requestData = config.data

        if (utils.isFormData(requestData) && utils.isStandardBrowserEnv()) {
          delete requestHeaders['Content-Type']
        }

        let request = new XMLHttpRequest()

        const fullPath = buildFullPath(config.baseURL, config.url)

        request.open(config.method.toUpperCase(), buildURL(fullPath, config.params, config.paramsSerializer), false)

        if ('setRequestHeader' in request) {
          utils.forEach(requestHeaders, function setRequestHeader(val, key) {
            if (typeof requestData === 'undefined' && key.toLowerCase() === 'content-type') {
              delete requestHeaders[key]
            } else {
              request.setRequestHeader(key, val)
            }
          })
        }

        if (!utils.isUndefined(config.withCredentials)) {
          request.withCredentials = !!config.withCredentials
        }

        if (responseType && responseType !== 'json') {
          request.responseType = config.responseType
        }

        if (!requestData) requestData = null

        const protocol = parseProtocol(fullPath)
        if (protocol && ['http', 'https', 'file'].indexOf(protocol) === -1) {
          handleReturnCallback(instance, config, request, new AxiosError('Unsupported protocol ' + protocol + ':', AxiosError.ERR_BAD_REQUEST, config), true)
          return
        }

        function handleLoad() {
          if (!request || request.readyState !== 4) return
          if (request.status === 0 && !(request.responseURL && request.responseURL.indexOf('file:') === 0)) return

          const responseHeaders = 'getAllResponseHeaders' in request ? parseHeaders(request.getAllResponseHeaders()) : null
          const responseData = !responseType || responseType === 'text' || responseType === 'json'
            ? request.responseText
            : request.response
          const response = {
            data: responseData,
            status: request.status,
            statusText: request.statusText,
            headers: responseHeaders,
            config: config,
            request: request
          }
          request.status >= 200 && request.status < 300 || request.status === 304
            ? handleReturnCallback(instance, config, request, response)
            : handleReturnCallback(instance, config, request, new AxiosError('Network Error', AxiosError.ERR_NETWORK, config, request, response), true)
          request = null
        }

        request.send(requestData)
        handleLoad()
      })(config)
    } catch (error) {
      return {
        code: '-1',
        msg: 'Unexpected Error in SyncXhrAdapter',
        error
      }
    }

    return {
      code: '0',
      msg: 'SyncXhrAdapter Work Expectant'
    }
  }
}

export function synchronizeInstance(instance) {
  instance.defaults.adapter = createSyncXhrAdapter(instance)
  instance.interceptors.request.forEach = instance.interceptors.response.forEach = function noop() { }
  instance.interceptors.response.handlers.unshift({ fulfilled: onAdapterResolution })
  return instance
}
