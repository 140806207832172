import schttp from 'public/src/services/schttp'
import { SILogger } from 'public/src/pages/common/monitor/index.js'

let prefetchCsrf = undefined
let MAX_RETRIES = Number(gbCommonInfo.CSRFTOEKN_MAX_RETRIES)
if (Number.isNaN(MAX_RETRIES)) {
  MAX_RETRIES = 0
}
let RETRY_DELAY = 500
if (typeof window !== 'undefined') {
  if (window.localStorage.getItem('csrfToken_delay')) {
    RETRY_DELAY = Number(window.localStorage.getItem('csrfToken_delay'))
  }
}
let  retries = MAX_RETRIES
function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

async function fetchWithRetry(fetcher) {

  const retry = async () => {
    if (retries > 0) {
      await delay(RETRY_DELAY)
      retries--
      return fetchWithRetry(fetcher)
    }
  }
  try {
    const res = await Promise.race([
      fetcher(),
      new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 6000))
    ])
    if (!res) {
      return retry()
    }
    return res
  } catch (error) {
    return retry()
  }
}

export function triggerPrefetchCsrf() {
  if (prefetchCsrf) {
    return prefetchCsrf
  }

  if (typeof window !== 'undefined') {
    prefetchCsrf = fetchWithRetry(() => schttp.updateXCsrfToken())
      .catch(error => {
        SILogger.logInfo({
          tag: 'SHEIN_PC_COMMON_CSRF_TOKEN',
          message: 'update_csrf_token_error',
          params: {
            scene: gbCommonInfo?.templateType,
            retries,
            msgData: error
          }
        })
      })
      .finally(() => {
        prefetchCsrf = undefined
      })
  }

  return prefetchCsrf || Promise.resolve()
}
