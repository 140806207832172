/* Do not edit any information in this file */
import axios from 'axios'

// const log = ({ method, url, data, params, config = {} } = {}, type) => (
//   console.log(`%c [axios] in ${type} ${method || config.method} %c ${url || config.url} %c`, 'background:#35495e ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff',
//   `background:${{
//     request: '#dadada',
//     response: '#41b883',
//     error: '#d76156',
//   }[type]}; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff`,
//   'background:transparent', data || params)
// )

const REQUEST_interceptors = [
  // [e => { log(e, 'request'); return e }],
]
const RESPONSE_interceptors = [
  // [e => { log(e, 'response'); return e }],
]
export function assignInterceptors(instance) {
  try {
    REQUEST_interceptors.forEach(interceptor_set => instance.interceptors.request.use(...interceptor_set))
    RESPONSE_interceptors.forEach(interceptor_set => instance.interceptors.response.use(...interceptor_set))
  } catch (error) {
    console.error('assignInterceptors error', error)
  }
  return instance
}

; (() => {
    // 谨防 SSR 改写 node axios
    if (typeof window === 'undefined') return
    // ========== rewrite create function binding default interceptors ==========
    axios._create = axios.create
    axios.create = function create(axiosRequestConfig) {
      const axiosInstance = axios._create(axiosRequestConfig)
      return assignInterceptors(axiosInstance)
    }
    // ========== END ==========

    // ========== set defaults configs below ==========
    // ...
    // ========== END ==========

    assignInterceptors(axios)
})()


export default axios
