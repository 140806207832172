import schttp from 'public/src/services/schttp'
import schttpSync from 'public/src/services/schttp/sync'

export function checkSchttp ({isAsync, opt, successCb, errorCb, completeCb}) {
  if (isAsync) {
    schttp({
      ...opt
    }).then(res => {
      successCb ? successCb(res) : ''
    }).catch(err => {
      errorCb ? errorCb(err) : ''
    }).finally((res) => {
      completeCb ? completeCb(res) : ''
    })
  } else {
    schttpSync({
      ...opt,
      success: (res => {
        successCb ? successCb(res) : ''
      }),
      error: (err => {
        errorCb ? errorCb(err) : ''
      }),
      complete: (res => {
        completeCb ? completeCb(res) : ''
      })
    })
  }
}